export enum VideoAspectRatios {
    HORIZONTAL = 'horizontal',
    VERTICAL = 'vertical',
    SQUARE = 'square',
    HORIZONTAL__MOBILE = 'horizontal--mobile',
    VERTICAL_MOBILE = 'vertical--mobile',
    SQUARE_MOBILE = 'square--mobile',
}

export enum Breakpoints {
    SMALL = 640,
    MEDIUM = 1024,
    LARGE = 1440,
}

export function updateVideoStyleAndSource(videoElement: HTMLVideoElement) {
    const parentElement = videoElement.parentElement!
    if (window.innerWidth <= Breakpoints.SMALL && videoElement.dataset.mobile && videoElement) {
        videoElement.src = videoElement.dataset.mobile

        switch (videoElement.dataset.stylemobile) {
            case VideoAspectRatios.HORIZONTAL:
                parentElement.classList.add(VideoAspectRatios.HORIZONTAL)
                break
            case VideoAspectRatios.VERTICAL:
                parentElement.classList.add(VideoAspectRatios.VERTICAL)
                parentElement.classList.add(VideoAspectRatios.VERTICAL_MOBILE)
                break
            case VideoAspectRatios.SQUARE:
                parentElement.classList.add(VideoAspectRatios.SQUARE)
                parentElement.classList.add(VideoAspectRatios.SQUARE_MOBILE)
                break
            default:
                break
        }
    } else if (videoElement.dataset.desktop) {
        videoElement.src = videoElement.dataset.desktop

        switch (videoElement.dataset.styledesktop) {
            case VideoAspectRatios.HORIZONTAL:
                parentElement.classList.add(VideoAspectRatios.HORIZONTAL)
                break
            case VideoAspectRatios.VERTICAL:
                parentElement.classList.add(VideoAspectRatios.VERTICAL)
                break
            case VideoAspectRatios.SQUARE:
                parentElement.classList.add(VideoAspectRatios.SQUARE)
                break
            default:
                break
        }
    }
}

export function removeAllAspectRatioClasses(videoElement: HTMLVideoElement) {
    const allAspectRatios: string[] = Object.values(VideoAspectRatios)
    allAspectRatios.forEach(ratioClass => {
        videoElement.parentElement!.classList.remove(ratioClass)
    })
}

export function crossedBreakpoint(currentWidth: number, lastWidth: number) {
    return Object.values(Breakpoints).some(breakpoint => {
        return (currentWidth <= breakpoint && lastWidth > breakpoint) ||
            (currentWidth > breakpoint && lastWidth <= breakpoint)
    })
}
