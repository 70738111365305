import { onDOMReady } from 'lambda-dom'
import { ModernSVGAnimationElement } from '@app-types/SVGAnimationElement.types'
import {
    crossedBreakpoint,
    updateVideoStyleAndSource,
    removeAllAspectRatioClasses,
} from './video.lib'

onDOMReady(() => {
    const videoComponents = document.querySelectorAll('.banner-video') as unknown as HTMLElement[]
    let lastWindowWidth: number = window.innerWidth

    function updateAllVideos() {
        videoComponents.forEach(videoComponent => {
            const videoElement = videoComponent.querySelector('.banner-video__video') as HTMLVideoElement
            if (videoElement) {
                removeAllAspectRatioClasses(videoElement)
                updateVideoStyleAndSource(videoElement)
            }
        })
    }

    window.addEventListener('resize', () => {
        const currentWidth = window.innerWidth
        if (crossedBreakpoint(currentWidth, lastWindowWidth)) {
            updateAllVideos()
            lastWindowWidth = currentWidth
        }
    })

    videoComponents.forEach((videoComponent) => {
        const videoElement = videoComponent.querySelector('.banner-video__video') as HTMLVideoElement
        if (! videoElement) {
            return
        }

        const videoControlsElement = videoComponent.querySelector('.banner-video__controls') as HTMLElement
        const placeholderElement = videoComponent.querySelector('.banner-video__placeholder') as HTMLVideoElement

        const toPauseAnimation = videoComponent
            .querySelector('.animation-to-pause') as ModernSVGAnimationElement
        const toPlayAnimation = videoComponent
            .querySelector('.animation-to-play') as ModernSVGAnimationElement

        const playPauseButton = videoComponent.querySelector('.banner-video--play-pause') as HTMLButtonElement

        playPauseButton.addEventListener('click', () => {
            if (videoElement.paused) {
                videoElement.play()
                toPauseAnimation.beginElement()
            } else {
                videoElement.pause()
                toPlayAnimation.beginElement()
            }
        })

        if (placeholderElement) {
            videoElement.addEventListener('canplaythrough', () => {
                placeholderElement.classList.add('hidden')
                videoElement.classList.add('active')
                videoControlsElement.classList.add('active')
            })
        } else {
            videoElement.classList.add('active')
            videoControlsElement.classList.add('active')
        }
    })

    updateAllVideos()
})
